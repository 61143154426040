import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesAnchor: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Anchor: Innocent Maid guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_anchor.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Anchor: Innocent Maid Guide & Review</h1>
          <h2>
            A guide & review for Anchor: Innocent Maid in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>10/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Anchor: Innocent Maid" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="anchor-innocent-maid"
                  enablePopover
                />
              </div>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Since we have poured our heart into Mast's essay, we will keep
                Anchor's review short. In a nutshell, Anchor is MastM's destined
                pair, enabling her entire kit and neutralizing her downsides.
                Together, they are powerful for coreless environments, and the
                QoL boost they provide is unparalleled due to the healing they
                provide.
              </p>
              <p>
                Similar to MastM, Anchor can also be used alone in the off-burst
                or even main burst B2 positions (although highly situationally)
                to provide the team with Distributed Damage buff, ATK buff,
                Reload Speed, and occasional healing (if you use Burst Skill or
                have another Aegis member in team). This can also activate
                Crown's S2 albeit less frequently compared to HelmTr or Naga.
                Anchor's main selling points, however, are her
                survivability-focused Burst Skill that grants the team Overheal.
                This allows the team to store excess HP recovery and use them to
                tank otherwise lethal attacks in PvE and PvP content. Along with
                this, her ability to cleanse off Mast’s Debuff is what makes her
                the perfect unit to pair alongside Mast.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                <strong>Absolutely</strong>, especially if you have pulled or
                are planning to pull MastM. Their synergy is outstanding, and it
                is compulsory to pair them together in content that requires
                setting up multiple teams. However, if you are a casual player
                or lean towards the casual side,{' '}
                <strong>you may skip this banner to save up for EVA 2.0</strong>{' '}
                if you lack funds for it since both Maids: Mast and Anchor, will
                enter the wishlist.
              </p>
              <p>
                Is Anchor better than Mast when used as an independent off-burst
                B2? Not really. Although Anchor's buffs are not far behind and
                more stable, you are limited to utilizing her S1 and S2 buffs
                because her Burst Skill is weak on the offense (unless the extra
                survivability matters). The key difference is that a substantial
                portion of Anchor's ATK is consumed outside Full Burst. However,
                in exchange, her Reload Speed buff always stays at 40.4%▲, lasts
                for 15s, and does not fluctuate.
              </p>
              <p>
                Additionally, Anchor is also an incredibly usable unit for PvP.
                The current meta in PvP revolves around bruising and wiping and
                anti-wiping, which sucks because there is barely any counter.
                Fortunately, Anchor's arrival saves the day, somewhat. Anchor's
                Burst Skill allows the team to tank a larger amount of instant
                damage and additionally provides the team with recovery on top
                of HP potency if paired with another Aegis member like HelmTr.
                This makes her a great alternative for Scarlet-XAnne comps and a
                core unit for stalling comps especially with HelmTr.
              </p>
              <p>
                In conclusion, if you are a core Solo Raid or Union Raid player
                or care about your PvE well-being, pull her (and MastM). If you
                are an avid PvP player, pull her too. There is nothing better
                than having a unit for security. Besides, who knows the EVA 2
                units will harmonize with them better?
              </p>
              <p>
                <strong>Is she worth the mileage?</strong> If you already have
                Mast and somehow ran out of gems when attempting to pull for
                Anchor, then perhaps it's justifiable.{' '}
                <strong>
                  Otherwise, if you do not have enough resources, it's
                  definitely better to skip both Mast and Anchor since EVA 2 is
                  drawing close
                </strong>
                . Examine their kits first before making any rash decisions. We
                will share our initial thoughts on newly released kits on our
                Discord Server, so make sure to join our community to avoid
                missing out on any important information. Remember, EVA won’t
                come back, but Mast and Anchor will go into the wishlist, so
                probably hold on to that mileage.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Anchor is a survivability-focused 40s B2 that naturally grants
                the team ATK, Distributed Damage, and Reload Speed, along with
                recovery if certain conditions are met or her Burst Skill is
                used. Anchor wields a non-clip RL, which makes her better than
                other weapons when it comes to generating burst gen.
              </p>
              <h5>Skill 1</h5>
              <blockquote>
                <p>■ Activates when entering Full Burst. Affects all allies.</p>
                <p>
                  Effect changes according to the activation time(s). Previous
                  effects trigger repeatedly.
                </p>
                <p>Once: Potency of HP ▲ 30.96% for 5 sec.</p>
                <p>Twice: Distributed Damage ▲ 30.4% for 10 sec.</p>
                <p>Three times: Stack count of debuffs ▼ 1.</p>
                <p>
                  ■ Activates when entering Full Burst with an ally from the
                  same squad still on the battlefield. Affects all allies.{' '}
                </p>
                <p>Recovers 3.04% of caster's Max HP every 1 sec for 8 sec. </p>
              </blockquote>
              <p>
                Anchor grants the team a mixture of buffs depending on how many
                times the team has entered Full Burst. Previous effects will
                retrigger.
              </p>
              <ul>
                <li>
                  The first trigger grants HP potency, which is often useless
                  for PvE but good for PvP. However, it pairs well with the
                  second component of this skill. It does <strong>not</strong>{' '}
                  affect her Burst Skill healing, though.
                </li>
                <li>
                  The second trigger grants Distributed Damage▲, an offensive
                  buff that boosts distributed damage. Currently, it is a bit
                  niche because the only good in-burst distributed damage dealer
                  is SBS. QEQ does not count because her Distributed Damage is
                  not affected by S1.
                </li>
                <li>
                  And the last one grants a debuff stack count reduction that
                  prevents Mast from stunning herself. This action, however,
                  does <strong>not</strong> dispel non-stackable debuffs.
                </li>
              </ul>
              <p>
                Additionally, if there is another Aegis member in the team,
                which can be the new Mast or old Mast, her R self, or either
                Helm variant, she will continuously regenerate the team's HP
                over 8 seconds. As long as the condition is met, you can treat
                this as an off-burst healing, and the effect is comparable to
                Blanc's Full Burst healing.
              </p>

              <Alert variant="primary">
                <p>
                  Anchor's Burst Skill grants the team a maximum of 60.19% of
                  caster's Max HP (base, not final) as overheal. However, her
                  Burst Skill only heals up to 40.18% of it. With another Aegis
                  member in the team, you can ensure that the overheal will
                  always be filled to the brim.
                </p>
              </Alert>
              <h5>Skill 2</h5>
              <blockquote>
                <p>■ Activates after Full Burst ends. Affects all allies.</p>
                <p>
                  Effect changes according to the activation time(s). Previous
                  effects trigger repeatedly.
                </p>
                <p>Once: Hit Rate ▲ 10.13% for 10 sec.</p>
                <p>Twice: ATK ▲ 35.02% of caster's ATK for 10 sec.</p>
                <p>Three times: Reloading Speed ▲ 40.04% for 15 sec.</p>
              </blockquote>
              <p>
                Depending on how many times the team has exited Full Burst,
                Anchor grants the team a mixture of tactical buffs. Previous
                effects retrigger.
              </p>
              <ul>
                <li>
                  The first trigger grants the team increased Hit Rate▲, which
                  helps offset Mast's self-debuff and boosts the team's core hit
                  rate, but it is nowhere impactful. This allows Mast to achieve
                  perfect accuracy with only 3× Hit Rate OL lines, hence our
                  passable recommendation.
                </li>
                <li>
                  The second trigger enhances the team ATK for 10s. Pretty
                  self-explanatory, but we do want to mention, depending on how
                  fast you cycle Burst, its overlap duration with Full Burst
                  will lengthen or shorten.
                </li>
                <li>
                  The last one is the most valuable aspect of this skill: Reload
                  Speed. Lasting for 15s, it grants the team a lot of uptime
                  increase and QoL boost for charging burst gen. When paired
                  with MastM's Reload Speed, you can achieve perfect reload or
                  “infinite ammo”, a phenomenon where SRs/RLs will not drop a
                  single ammo when shooting. This ensures that at the end of
                  Full Burst, they will have full magazines, allowing for
                  seamless burst gen without reloading.{' '}
                </li>
              </ul>
              <Alert variant="primary">
                <p>
                  Anchor's 10s ATK buff and 15s Reload Speed buff are her
                  greatest assets. You want to make the most of them by making
                  them overlap with FB time as much as possible, which requires
                  cycling Burst fast. Learn how to prepare Full Charge toward
                  the end of Full Burst with SR/RL weapons and release them as
                  soon as the Full Burst ends, then follow up with spamming. You
                  might need to swap between units.
                </p>
              </Alert>
              <h5>Burst Skill</h5>
              <blockquote>
                <p>■ Affects all allies.</p>
                <p>
                  Storage: If the target obtained a healing effect that exceeds
                  the character's Max HP, excess portion will be stored, up to
                  60.19% of the caster's Max HP, lasts for 25 sec.
                </p>
                <p>Recovers 40.18% of caster's Max HP as HP.</p>
                <p>ATK ▲ 30.09% of caster's ATK for 10 sec.</p>
              </blockquote>
              <p>
                Anchor grants Storage to all allies, heal them a large amount,
                and boost their ATK for 10s.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> What is
                  Storage?
                </h6>
                <p>
                  Storage, a buff introduced first by Marciana, is the
                  capability of storing excess recovery. The overheal is stored
                  as extra HP on top of the current HP, but it is not treated as
                  Max HP▲ (so no buffs to Max HP-scaling skills), nor does it
                  deactivate "Triggers at max HP" skills if not fully filled. In
                  other words, they are just a weaker Max HP▲ that offer the
                  same survivability bonuses.
                </p>
              </blockquote>
              <p>
                Anchor's Storage lasts for 25 seconds, which means it can
                potentially last permanently if the team rotates very fast, but
                in most cases it will just drop right before her Burst, but
                that's fine as it doesn't impact the effect. The long duration
                is beneficial for allowing the team to tank damage that would
                have otherwise killed them.
              </p>
              <Alert variant="primary">
                <p>
                  Storage synergizes well with her S1 and also outside recovery
                  from Noise, Helm, etc. This is because they can also fill the
                  Storage, not just Anchor. However, this is not needed for most
                  content.
                </p>
              </Alert>
              <p>
                After applying Storage, Anchor subsequently heals the team based
                on her final Max HP. Since this chronologically happens after
                Storage, it can fill the Storage, which means her heal will not
                be wasted. The Storage + Heal combo is powerful in PvP where the
                padded HP is important for tanking critical AoE attacks.
              </p>
              <p>
                Lastly, Anchor also grants the team ATK buff. It's small but
                nothing to scoff at. It adds an offensive spark to her defensive
                kit. Good for PvP, although don't expect her to make your wiper
                a terminator. Remember that it scales off base ATK, so make sure
                to OL her helmet, vest, and gloves for stat.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Anchor is the weaker of the bunch, since Mast can be paired with
                Crown for some off-burst power. However, Mast is supposed to be
                paired with Anchor, who can negate her downsides. Compared to
                Crown, the team is weaker in Survivability in Campaign due to
                having no shield (mobs there one-shot at higher deficits). You
                are also limited to 2 B3 and cannot use RH-B1 + B1/B2 CDR strat.
                It is a good alternative especially when it comes to buffing DPS
                that have weak core damage, such as SBS, or units with reload
                speed scaling like Rapipi and Alice. The problem? Well, they
                need to stack before their buffs even become impactful. And you
                also need high skill investments.
              </p>
              <p>
                Just like Bunnies, using them in Campaign means you won’t be
                able to pull some extremely hairloss move where you beat the
                stage around 40% deficit. But let those bald guys mald! We
                casual gang just want something easy to operate, hence we run
                Bunnies 2.0 Aka El Macho. They have ATK buffs and healing, which
                are something we want in Campaign situationally. They are good
                for bossing, but Crown + HelmTr might still be better.
              </p>
              <p>
                If a player does not have Crown, Maid Anchor and Maid Mast are
                probably the next best thing after the Tia-Naga duo. Blanc-Noir
                compete closely, but the fact that the Maid Duo provides buffs
                to allies and not debuffs to opponents makes them more usable in
                the overall meta! With Tia's taunt being hard to use for most
                players, Maid Duo can provide a very usable alternative in
                Campaign to players without Crown.
              </p>
              <h5>
                Bossing - <strong className="ss">SS</strong>
              </h5>
              <p>
                Anchor is Mast's comrade in arms. You can hardly separate them
                in content that rely on min-maxing. As the new powercreep to
                Blanc-Noir and situationally better than the Tia Naga duo, the
                Maids will have their place in Team 2-4 in Solo Raid (and likely
                be one of the mainstays in Union Raid too). Solo Raid bosses
                usually don't have a core, which gives them an edge. Not only
                that, we have 2 DPS that scale extremely well on Reload Speed:
                Alice and Rapipi. Both of them are more likely to be paired with
                the Maids, although element-related restrictions may interfere.
              </p>
              <Alert variant="primary">
                <p>
                  We expect that the Maids will be paired with SBS and Alice for
                  most raids, except on Electric-weak Raids, where adjustments
                  need to be made due to lack of decent Electric B1 CDR outside
                  Rouge. In water weak raids, we may even see Quency: Escape
                  Queen being paired with the Maids.
                </p>
              </Alert>
              <p>
                In content that relies solely on maxxing (outside Raids), her
                impact on the meta will depend on Crown's effectiveness. If
                Crown is the "absolute best" and is indispensable, then Anchor
                will probably see less usage than Mast as the other might be the
                better off-burst support. However, that doesn't prohibit you
                from using her in that fashion. Who knows she might be better?
                More stable reload speed, longer but weaker ATK buffs, etc.
                Additionally, if she is paired with another Aegis member—which
                doesn't have to be Mast—she can also trigger Crown's S2.
              </p>
              <p>
                There is also a very decent QoL on Kraken, which we will detail
                in the team building section.
              </p>
              <p>
                Lastly, we also want to reiterate that the Maids are one of the
                best supports for coreless content, below Crown + HelmTr.
                Despite numbers showcasing them potentially being weaker than
                Naga variants in cored contents, they are still a valuable asset
                to invest in (unless SU powercreeps them like Rem [poor her]).
              </p>
              <h5>
                PVP - <strong className="s">S</strong>
              </h5>
              <p>
                Anchor in PvP is a survivability-focused B2 unit that has some
                offensive power, comparable to XAnne. Both provide the team
                damage and boost their survivability in different ways. One
                gives revive + healing to Attackers, and the other relies on
                teamwide healing and padding. While XAnne might be better in
                most comps due to her tremendous impact with revive and
                obviously stronger ATK buff, Anchor is a great alternative for
                PvE players who don't want to invest in PvP. Since you will be
                building her for PvE, you can also just slap her there and call
                it a day.
              </p>
              <p>
                A main use for Anchor might be alongside other healers. This
                way, Anchor can be a crucial unit for blocking AoE attacks. Her
                HP padding + potential synergy with HelmTr or other B1 Healers
                allows for some unique stalling properties. However, as a B2,
                she cannot be triggered alongside other B2 units, so no Nero +
                Anchor combo (except in 2 FB comps). :/
              </p>
              <p>
                We also have an announcement to make here! We hope to deliver
                updated PvP information by next month with the advent of
                Champions Arena to you all! The tier list rework for 5 teams is
                already in works!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Just like Mast, as a supporter, Anchor doesn’t need specific OL
                lines. You still want to OL her gear for higher base stats
                (because her kit scales on caster’s ATK). Prioritize Helmet,
                Vest/Body, and Gloves. Boots are optional because they don’t
                give ATK stat, but the extra CP padding may be useful for
                Campaign or Tower.
              </p>
              <p>
                1-2× Max Ammo is a good QoL for Burst Generation in cases where
                Alice is not used alongside the Maid Duo. We advise you keep
                whatever you get initially (T11s are good for CP padding) and
                don’t attempt to reroll.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong>
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK, 1-2× Max Ammo
                </li>
                <li>
                  <strong>Passable:</strong>
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Anchor, like Mast, is an investment heavy unit. Her budget skill
                recommendations mostly rely on what Mast’s skills are.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 4/7/10</strong>
                </li>
                <ul>
                  <li>
                    Can leave at 4 or 7 for budget. This skill mostly has to do
                    with healing, and the cleanse works at lvl1 as well.
                    Ultimately, due to the Distributed Damage buff in this
                    skill, you want to max out this skill once your budget
                    allows you to.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7/8/9/10</strong>
                </li>
                <ul>
                  <li>
                    Prioritize this skill because it not only gives ATK Buff,
                    but also gives Reload Speed. For other weapons, you can
                    settle at Lv 7. However, for units like Alice, you want this
                    skill to be minimum Lv 7 (with Lv 10 Mast’s S2), or Lvl 8~9
                    (with Lvl 9 Mast’s S2) to obtain “infinite ammo” through
                    having &gt;109% reload speed. Alice will literally never
                    drop a single bullet in 4th+ Full Burst (disclaimer: only
                    with Resilience 7+). Eventually, due to the ATK buff in this
                    skill, you have to get it up to 10 as well, unfortunately.
                    Damage is damage after all, and the scaling is the normal
                    ×1.69 (from lvl 1-&gt;lvl 10)for the ATK buff.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:4/7/10</strong>
                </li>
                <ul>
                  <li>
                    A lot of Healing and ATK buffs to go alongside it. This
                    skill is similar to Skill 1 in priority since it is mostly
                    healing focused with an ATK buff attached to it. As usual,
                    offensive buffs mean that in the end you want to max out
                    this skill as well.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="anchor-innocent-maid" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <p>
                For true “infinite ammo”, you will need Resilience 7+ and
                Mast:RM or Crown.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_11_sm.webp"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_8_sm.png"
                alt="Guide"
              />
              <p>PvP cubes to improve Anchor’s survivability.</p>
              <h5>Doll Investments</h5>
              <p>
                Same as Mast, she is not worth too much doll investment because
                her own DPS is meh. However, she scales off base ATK stat, and
                for all we know, dolls’ stats are undiscriminating. It gives the
                same base ATK stat boost to a Defender as it does to an
                Attacker. Hence, putting a leveled Doll on Anchor can prove
                worthy due to the ubiquitous caster’s ATK buffs.
              </p>
              <p>
                Depending on your competitiveness in Solo Raid, either SR 0 or
                SR 5 should be your end goal. SR 15 feels excessive even though
                you can justify it.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Reload Heaven</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="scarlet-black-shadow"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="alice" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>or</p>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                In this comp, we focus on overloading our units with maximum
                Reload Speed, allowing them to fire to their heart's content.
                Both Rapipi and Alice scale well with this. Crown is likely the
                better option here despite Mast's alliance with Anchor. This is
                not something you'd normally do in Solo Raid, but it might be
                something viable for Union Raid or Anomaly Interception, so keep
                that in mind! If you use Crown, then Anchor can be treated as an
                off-burst B2 or occasional B2.
              </p>
              <h5>Team #2: Fully Auto Stage 7 - Kraken is down!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="scarlet-black-shadow"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                While we did indeed mention that Anchor’s fate is destined to be
                to take care of a drunk Mast, we have news for you. Insane QoL
                update alert! By far the best team yet for an easy Kraken daily
                run. This team is able to comfortably stage 6 or stage 7 Kraken
                provided your DPS are decently invested into. With above average
                investments, players should be able to get to stage 7 on full
                auto with this comp. Players may need doll investments to
                survive the QTE on full auto.
              </p>
              <h5>Team #3: Misty’s Water Pokemon</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="ludmilla-winter-owner"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm"
                      enablePopover
                      showTreasure
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="guillotine-winter-slayer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Team for Harvester Anomaly Interception, or Water weak stages.
                Infinite Ammo means infinite shooting. The unfortunate thing
                about this team is that 2 units are limited, and one unit is
                locked behind Favorite Item skills. Quency and the support units
                are the only non-limited units.
              </p>
              <h5>Team #4: Elysion Tower</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm"
                      enablePopover
                      showTreasure
                    />
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="privaty-unkind-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                2025. The year of Elysion! Ingrid stays cooking. The duo
                provides a new perspective for Elysion tower meta, and takes
                over the job of healing, burst gen and buffing, all at once! RRH
                insanely benefits from their buffs, and while the remaining B3
                spot is quite weak in terms of pushing higher deficits, we still
                have solid options and even some synergy in Phantom! We finally
                have something solid for Elysion Tower, so start pushing that
                stage you have been stuck on for ages!
              </p>
              <h5>Team #5: Let the Distributed Damagers cook! </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="mast-romantic-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="phantom" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                While Volume is a more solid option due to faster burst
                rotations, Dorothy can also work mildly well here. This team
                consists of 0 limited/pilgrim units if you consider Volume as
                the main B1-CDR unit. It’s debut has been in the first 18 bil
                score of the current ongoing Material H (Water Weak) Solo Raid
                in NA server, by the player DON, showcasing its strength in the
                current meta over 5 teams!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Scarletto's Arc</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="laplace"
                      enablePopover
                      showTreasure
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="maiden-ice-rose"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Two of the most common pairings for Scarlet in the Scar-Jack
                comp are Blanc and XAnne. Now, Anchor can join them. Instead of
                focusing on reviving and pressuring enemy adjustments, Anchor
                heals the team and boosts Scarlet's damage without feeding
                Jackal.
              </p>
              <h5>H E A L I N G</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noise" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="anchor-innocent-maid"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm"
                      enablePopover
                      showTreasure
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="mana" enablePopover />
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Two revives. 4-5 healers. 2RL with Jackal, 3RL with Mana. Do NOT
                let them burst! A fast burst means Helm can snipe out a jackal
                link. A slower burst means you need to kill this absolute tank
                of a team!
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Very decent healing, allows for comfortable Raid
                          teams, and some unique PvP teams.
                        </li>
                        <li>
                          Has cleanse - a rare and situationally very meta
                          mechanic to have in Nikke.
                        </li>
                        <li>
                          Cleanse comes after 3 bursts, synergizing perfectly
                          with Mast: Romantic Maid.
                        </li>
                        <li>Decent Distributed Damage▲ buff.</li>
                        <li>
                          Provides an overall high value Caster’s ATK buff for
                          all allies
                        </li>
                        <li>
                          Provides the unique Reload Speed buff for all allies.
                        </li>
                        <li>
                          Join the Community Uplift Network of New Youngsters!
                        </li>
                        <li>
                          Let ingrid cook. Elysion Tower duo is finally here!
                          Slowly but surely we are watching a tribe tower being
                          revived from the rubble.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>10/10/10 supporter.</li>
                        <li>40s B2.</li>
                        <li>
                          Requires another 10/10/10 to Mast as a pair to work at
                          her peak in PvE.
                        </li>
                        <li>ATK▲ buffs are common and easily diluted.</li>
                        <li>
                          Needs time to stack some of her buffs, reducing her
                          usability in Campaign and PvP.
                        </li>
                        <li>
                          A lot of caster’s ATK buffs make her very
                          investment-dependent.
                        </li>
                        <li>
                          PSA: Very very dangerous to look at in public. Be
                          careful out there..
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesAnchor;

export const Head: React.FC = () => (
  <Seo
    title="Anchor: Innocent Maid Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Anchor: Innocent Maid in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
